import React, { useEffect, useState } from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
// import barcodeImg from "../../assets/images/barcode.png"
import axios from "axios";
// import BusAdd from "./BusAdd";
import { baseUrl } from "../../config/baseUrl";
import { ToastMessgae } from "../utils/toast";
// import { Link } from "react-router-dom";
import CompanyAdd from "./CompanyAdd";
import DeleteToast from "../Users/DeleteToast";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

export default function CompanyList() {
  let [companyNameData, setCompanyNameData] = useState([]);
  let [apicall, setApicall] = useState(1);
  let [deleteid, setDeleteid] = useState();
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLimit, setPageLimit] = useState(1);

  useEffect(() => {
    const companyName = async () => {
      let response = await axios(
        `${baseUrl}/module/admin/company-viewset?page=${pageNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setCompanyNameData(response.data?.data || []);
      setPageLimit(response?.data?.extra?.total);
    };
    companyName();
  }, [apicall, pageNumber]);

  let deleteHandle = (id) => {
    // console.log(id);
    // return;
    (async () => {
      let response = await axios(
        `${baseUrl}/module/admin/company-viewset/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setCompanyNameData((prvVal) => {
        prvVal = prvVal.filter((arr) => arr.id !== response?.data?.data?.id);
        return [...prvVal];
      });
      if (response?.data?.code === 200) {
        ToastMessgae(response?.data);
      } else {
        ToastMessgae(response.data);
      }
    })();
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar activeItem={"CompanyManagement"} /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Company List
              </h4>
              <Link
                to="#"
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addbus"
              >
                <i className="fa fa-plus me-2" />
                Add
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Company
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>
                        Sr.no
                      </th>
                      <th>Company Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyNameData.map((arr, i) => {
                      return (
                        <tr key={arr.id}>
                          <td>
                            {++i + 10 * (pageNumber - 1)}
                          </td>
                          <td>
                            <span className="text-dark">
                              {arr?.company_name}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-danger"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#pupup"
                              onClick={() => setDeleteid(arr?.id)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <BusAdd setCallApi={setCallApi} /> */}
      <CompanyAdd apicall={apicall} setApicall={setApicall} />
      {/* <DeleteToast deleteHandle={deleteHandle} id={0} /> */}
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;
    </>
  );
}
