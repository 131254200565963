import React, { useEffect, useState } from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
// import barcodeImg from "../../assets/images/barcode.png"
import axios from "axios";
import BusAdd from "./BusAdd";
import { baseUrl } from "../../config/baseUrl";
import { ToastMessgae } from "../utils/toast";
// import { Link } from "react-router-dom";
import DeleteToast from "../Users/DeleteToast";
// import avtar from '../../assets/images/avtar.jpg';
import BusEdit from "./BusEdit";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import FileDownload from "./Demo";
export default function BusList() {
  let [busData, setBusData] = useState([]);
  let [formval, setFormval] = useState({ cleaning_type: "", date: "" });
  let [callApi, setCallApi] = useState(1);
  let [deleteid, setDeleteid] = useState();
  let [editVal, setEditval] = useState({
    company: "",
    depo: "",
    // : "",
  });
  // let [editBusId, setEditBusId] = useState(0);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLimit, setPageLimit] = useState(1);
  let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);
  // let [callbasApi, setcallApi] = useState(0);
  const [barcodeValue, setBarcodeValue] = useState("Initial Value");
  const [basId, setbasId] = useState();
  let loginUser = JSON.parse(localStorage.getItem("user"));
  let [searchData, setSearchData] = useState({
    company: "",
    depo: "",
    // assign_date:""
  });
  // let changeHandle = (e) => {
  //   // console.log(formval);
  //   setFormval((cul) => ({ ...cul, [e.target.name]: e.target.value }));
  // };

  let filterHandler = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    setSearchData((curr) => {
      return { ...curr, [e.target.name]: e.target.value }; 
    });
    // try {
    //   // https://admin.cleaningcrm.fluttertrends.com/module/admin/search-bus
    //   let response = await axios(`${baseUrl}/module/admin/search-bus`, {
    //     method: "GET",
    //     headers: {
    //       Authorization: "Token " + localStorage.getItem("token"),
    //     },
    //     data: { [e.target.name]: e.target.value },
    //   });
    //   console.log(response.data);
    //   if (response.data.code === 200) {
    //     return setBusData(response.data.data || []);
    //   } else if (response.data.code === 404) {
    //     ToastMessgae(response.data);
    //   } else if (response.data.code === 400) {
    //     ToastMessgae(response.data);
    //   }
    // } catch (error) {
    //   alert("some error from Backend");
    // }
  };

  const depo = async () => {
    let response = await axios(`${baseUrl}/module/admin/depo-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    // console.log(response.data);
    // setPageLimit(response?.data?.extra?.total);
    setDepoData(response?.data?.data || []);
  };
  // depo();
  const companyName = async () => {
    let response = await axios(
      // https://admin.cleaningcrm.fluttertrends.com/module/admin/company-list
      `${baseUrl}/module/admin/company-list`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    // console.log(response.data);
    setCompanyNameData(response.data?.data);
    //  setPageLimit(response?.data?.extra?.total);
  };

  useEffect(() => {
    depo();
    companyName();
  }, []);

  let BusList = async () => {
    let response = await axios(
      `${baseUrl}/module/admin/bus-viewset?page=${pageNumber}&company=${searchData.company}&depo=${searchData.depo}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    setPageLimit(response?.data?.extra?.total);
    setBusData(response?.data?.data || []);
    // console.log(response?.data);
  };

  useEffect(() => {
    try {
      BusList();
    } catch (error) {
      console.error("Error :", error.response.data);
    }
  }, [callApi, pageNumber]);

  // edit Data
  let editApi = async (id) => {
    let response = await axios(`${baseUrl}/module/admin/bus-viewset/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    // console.log(response?.data?.data);
    delete response?.data?.data.bar_code;
    delete response?.data?.data.generated_date;
    delete response?.data?.data.depo_address;
    setEditval(response?.data?.data);
  };

  let deleteHandle = (id) => {
    (async () => {
      let response;
      try {
        response = await axios(`${baseUrl}/module/admin/bus-viewset/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
      } catch (error) {
        return ToastMessgae(error?.response?.data);
      }
      setBusData((prvVal) => {
        prvVal = prvVal.filter((arr) => arr.id !== response?.data?.data?.id);
        return [...prvVal];
      });
      if (response?.data?.code === 200) {
        ToastMessgae(response?.data);
        BusList();
      } else {
        ToastMessgae(response.data);
      }
    })();
  };

  let handleSearch = async (e) => {
    // console.log(searchData);
    e.preventDefault();
    try {
      // https://admin.cleaningcrm.fluttertrends.com/module/admin/search-bus
      let response = await axios(
        `${baseUrl}/module/admin/bus-viewset?company=${searchData.company}&depo=${searchData.depo}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          // data:searchData
        }
      );
      // console.log(response.data);
      if (response.data.code === 200) {
        setBusData(response.data.data || []);
        return setPageLimit(response?.data?.extra?.total);
      } else if (response.data.code === 404) {
        ToastMessgae(response.data);
      } else if (response.data.code === 400) {
        ToastMessgae(response.data);
      }
    } catch (error) {
      alert("some error from Backend");
    }
  };
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar activeItem={"BusManagement"} /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-chart-bar me-2" />
                Bus List
              </h4>
              <Link
                to="#"
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addbus"
              >
                <i className="fa fa-plus me-2" />
                Add
              </Link>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    BusList
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-8">
                    <form action="">
                      <div className="row justify-content-end">
                        {loginUser.user_type === "SUPER_ADMIN" && (
                          <div className="col-lg-4">
                            <select
                              name="company"
                              id=""
                              onChange={filterHandler}
                              className="form-select"
                              value={searchData.company}
                            >
                              <option value="">Select Company</option>
                              {companyNameData?.map((arr) => (
                                <option value={arr?.id} key={arr?.id}>
                                  {arr?.company_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div className="col-lg-4">
                          <select
                            name="depo"
                            onChange={filterHandler}
                            className="form-select"
                            value={searchData?.depo}
                          >
                            <option value="">Select Depo</option>
                            {depoData?.map((arr) => (
                              <option key={arr?.id} value={arr?.id}>
                                {arr?.depo_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-2">
                          <button
                            className="thm-btn w-100"
                            onClick={handleSearch}
                          >
                            Submit
                          </button>
                        </div>
                        {/* <div className="col-lg-3">
                          <select
                            name="cleaning_type"
                            onChange={filterHandler}
                            className="form-select"
                            // value={formval.user_type}
                          >
                            <option value="">Select Cleaning Type</option>
                            <option value="REGULAR_CLEANING">
                              Regular Cleaning
                            </option>
                            ;
                            <option value="STANDARD_DEEP_CLEANING">
                              Standard Deep Cleaning
                            </option>
                            <option value="ANNUAL_DEEP_CLEANING">
                              Annual Deep Cleaning
                            </option>
                          </select>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="checkAll"
                          />
                        </div> */}
                        Sr No.
                      </th>
                      <th>Bus number</th>
                      <th>Barcode</th>
                      {/* <th> Cleaning Action</th>
                      <th> Cleaning Type</th>
                      <th> Cleaning Date</th> */}
                      <th>company</th>
                      <th>depo</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {busData.map((arr, i) => {
                      return (
                        <tr key={arr.id}>
                          <td>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input checkbox"
                                type="checkbox"
                                defaultValue=""
                                id="check"
                              />
                            </div> */}
                            {++i + 10 * (pageNumber - 1)}
                          </td>
                          <td>
                            <span>{arr?.bus_number}</span>
                          </td>
                          <td>
                            <Link>
                              <img
                                data-bs-toggle="modal"
                                data-bs-target="#qrcode"
                                src={arr?.bar_code}
                                // src={barcodeImg}
                                className="img-50"
                                alt=""
                                onClick={() => {
                                  setBarcodeValue(arr?.bar_code)
                                  setbasId(arr.id)
                                }}
                              />
                            </Link>
                          </td>

                          {/* <td>
                            <span className="dot-green"></span> &nbsp;
                            <span className="dot-red"></span> &nbsp;
                            <span className="dot-yellow"></span> 
                            <button
                             data-bs-toggle="modal"
                                data-bs-target="#type"
                                type="button"
                                onClick={()=>BusEditHandle(arr.id,i)}
                                className="ms-2 btn btn-outline-info btn-sm">Edit</button>
                          </td>
                          <td>
                            <span>{arr?.cleaning_type}</span>
                          </td>
                          <td>
                            <span>{arr?.date?.split("T")[0]}</span>
                          </td> */}
                          <td>
                            <span>{arr?.company_name}</span>
                          </td>
                          <td>
                            <span>{arr?.depo_name}</span>
                          </td>
                          {/* <td>
                            <a class="btn btn-primary" download="barcode.png"   href={arr?.bar_code} >
                              Download Now
                            </a>
                          </td> */}

                          <td>
                            {(loginUser.user_type === "ADMIN_MANAGER" || loginUser?.user_type === "CLEANING_MANAGER") && (
                              <Link
                                className="btn btn-outline-warning"
                                data-bs-toggle="modal"
                                data-bs-target="#editbus"
                                onClick={() => editApi(arr?.id)}
                              >
                                <i className="fa fa-edit" />
                              </Link>
                            )}
                            &nbsp;
                            <button
                              className="btn btn-outline-danger"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#pupup"
                              onClick={() => setDeleteid(arr?.id)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* add-bus-modal-start */}
      <BusAdd setCallApi={setCallApi} />
      <BusEdit
        editVal={editVal}
        setEditval={setEditval}
        apicall={callApi}
        setApicall={setCallApi}
      />
      {/* add-bus-modal-end */}
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;
      <div
        className="modal fade"
        id="qrcode"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <img src={barcodeValue} alt="barcode" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>


              <a class="btn btn-primary" download="2001.png" href={`${baseUrl}/module/admin/download-barcode/${basId}`} >
                Download Now
              </a>
              {/* <FileDownload downloadUrl={barcodeValue} /> */}
            </div>
          </div>
        </div>
      </div>
      {/* Edit model */}
      {/* <div
        className="modal fade"
        id="type"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="offEditModel"
              />
            </div>
          
            <form className="ms-3 mb-3 me-3" onSubmit={submitHandleType}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Select Date 
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="date"
                  min={today}
                  value={formval?.date}
                  onChange={changeHandle}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Select Cleaning Type 
                </label>
                  <select name="cleaning_type" className="form-select" onChange={changeHandle}  value={formval?.cleaning_type}>
                            <option  >Select Company Name</option>
                            <option value={"REGULAR_CLEANING"}>REGULAR_CLEANING</option>
                            <option value={"ANNUAL_DEEP_CLEANING"}>ANNUAL_DEEP_CLEANING</option>
                            <option value={"STANDARD_DEEP_CLEANING"}>STANDARD_DEEP_CLEANING	
                            </option>
                 </select>
              </div>    
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            
          </div>
        </div>
      </div> */}
      {/* <Demo/> */}
    </>
  );
}
