import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastMessgae } from "../utils/toast";
import axios from "axios";
// import UserAdd from "./UserAdd";
import { baseUrl } from "../../config/baseUrl";
import DeleteToast from "../Users/DeleteToast";
import Swal from "sweetalert2";
import Pagination from "../Pagination/Pagination";
export default function UserListTable({
  userData,
  setUserData,
  modelType,
  archiveData,
  setArchiveData,
  adminData,
  setAdminData,
  setArchiveCount,
  archivedVal,
  pageLimit,
  companyNameData,
  countApi,
  setSearchData,
  searchData,
  setPageNumber,
  pageNumber,
  setPageLimit,
  setPageNumberAdmin,
  pageNumberAdmin,
  setPageLimitAdmin,
  pageLimitAdmin,
  pageNumberArchive,
  setPageNumberArchive,
  pageLimitArchive,
  setPageLimitArchive,
}) {
  let loginUserType = JSON.parse(localStorage.getItem("user"));
  let [deleteid, setDeleteid] = useState();
  let [loginUserTypeType, setloginUserTypeType] = useState(loginUserType?.user_type);



  let handleSearchInput = (e) => {
    setSearchData((curr) => {
      return { ...curr, [e.target.name]: e.target.value };
    });
  }

  //api for Delete User
  let deleteHandle = (id) => {
    (async () => {
      let response = await axios(
        `${baseUrl}/module/admin/archive-viewset/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      if (response?.data?.code === 200) {
        if (response?.data?.data?.user_type === "ADMIN_MANAGER") {
          setAdminData((preVal) => {
            preVal = preVal?.filter(
              (arr) => arr?.id !== response?.data?.data?.id
            );
            return [...preVal];
          });
        }
        setUserData((preVal) => {
          preVal = preVal?.filter(
            (arr) => arr?.id !== response?.data?.data?.id
          );
          return [...preVal];
        });

        ToastMessgae(response?.data);
      } else {
        ToastMessgae(response?.data);
      }
    })();
  };

  //api for Restore user and archived user
  let archiveUser = (id, archived) => {
    let apiCall = async () => {
      let response = await axios(
        `${baseUrl}/module/admin/archive-viewset/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          data: { archived: !archived },
        }
      );
      setArchiveCount(response?.data?.data?.counts);
      await countApi()
      setUserData((prv) => {
        let filterData = prv.filter(
          (arr) => arr?.id != response?.data?.data?.id
        );
        return [...filterData];
      });
      setAdminData((prv) => {
        let filterData = prv.filter(
          (arr) => arr?.id != response?.data?.data?.id
        );
        return [...filterData];
      });
      setArchiveData((prv) => {
        let filterData = prv.filter(
          (arr) => arr?.id != response?.data?.data?.id
        );
        return [...filterData];
      });
    };
    Swal.fire({
      title: "Are you sure?",
      text: `You want be able ${archivedVal} this!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${archivedVal} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "Archived..!",
          text: `User has been ${archivedVal}..`,
          icon: "success",
        });
      }
    });
  };

  let handleSearch = async (e) => {
    e.preventDefault();
    setPageNumber(1)
    setPageNumberAdmin(1)
    try {
      let response = await axios(
        modelType !== "ADMIN_MANAGER" ? `${baseUrl}/module/admin/adminlist?company=${searchData.company}` : `${baseUrl}/module/admin/user-viewset?company=${searchData?.company}&user_type=${searchData?.user_type}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.code === 200) {
        { modelType === "ADMIN_MANAGER" ? setUserData(response.data.data || []) : setAdminData(response.data.data || []) }
        modelType === "ADMIN_MANAGER"?setPageLimit(response?.data?.extra?.total):setPageLimitAdmin(response?.data?.extra?.total)
      } else if (response.data.code === 404) {
        ToastMessgae(response.data);
      } else if (response.data.code === 400) {
        ToastMessgae(response.data);
      }
    } catch (error) {
      alert("some error from Backend");
    }
  };


  let handleSearchArchive = async (e) => {
    e.preventDefault();
    setPageNumberArchive(1)
    try {
      let response = await axios(`${baseUrl}/module/admin/archive-viewset?company=${searchData.company}&user_type=${searchData.user_type}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data);
      if (response.data.code === 200) {
        setArchiveData(response.data.data)
        setPageLimitArchive(response?.data?.extra?.total);
      } else if (response.data.code === 404) {
        ToastMessgae(response.data);
      } else if (response.data.code === 400) {
        ToastMessgae(response.data);
      }
    } catch (error) {
      alert("some error from Backend");
    }
  };

  return (
    <>
      <div className="cards user-list">
        <div className="row">
          <div className="col-lg-12">
            <div className="bus-filter">
              <div className="row justify-content-end">
                <form action="">
                  <div className="row justify-content-end  ">
                    <div className="col-lg-2">
                      {loginUserType.user_type === "SUPER_ADMIN" && <select
                        name="company"
                        onChange={handleSearchInput}
                        className="form-select"
                        defaultValue={searchData.company}
                      >
                        <option value="">Select Company </option>
                        {companyNameData?.map((arr) => {
                          return (
                            <option key={arr?.id} value={arr?.id}>
                              {arr.company_name}
                            </option>
                          );
                        })}
                      </select>}
                    </div>

                    {modelType !== "SUPER_ADMIN" && <div className="col-lg-2">
                      <select
                        name="user_type"
                        onChange={handleSearchInput}
                        className="form-select"
                        defaultValue={searchData.user_type}
                      >
                        <option value="">Select User Type</option>
                        <option value="CLEANER">Cleaner</option>;
                        <option value="CLEANING_MANAGER">
                          Cleaning Manager
                        </option>
                        <option value="CLIENT">Client</option>
                      </select>
                    </div>}

                    <div className="col-lg-2">
                      <button
                        className="thm-btn w-100"
                        onClick={archivedVal === "Restore" ?handleSearchArchive: handleSearch } 
                      >
                        Search
                      </button>
                    </div>


                    <div
                      className={`col-lg-2 ${modelType === "ADMIN_MANAGER"
                        ? (loginUserType?.user_type === "ADMIN_MANAGER" || loginUserType?.user_type === "CLEANING_MANAGER" ) && ( archivedVal !== "Restore")
                          ? "Add User"
                          : "d-none"
                        : (loginUserType?.user_type === "SUPER_ADMIN") && ( archivedVal !== "Restore") 
                          ? "Add admin"
                          : "d-none"
                        } `}
                    >
                      <NavLink
                        className="thm-btn w-100 text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#add-user"
                      >
                        {(modelType === "ADMIN_MANAGER" || loginUserType?.user_type === "CLEANING_MANAGER") &&  archivedVal !== "Restore"
                          ? "Add User"
                          :  ( archivedVal !== "Restore") &&  "Add admin"}
                      </NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="table table-responsive custom-table management-table">
              <table className="table table-borderless">
                <thead>
                  {modelType === "ADMIN_MANAGER" ? (
                    <tr>
                      <th>Sr No.</th>
                      <th>First Name</th>
                      <th>User Name</th>
                      <th>Mobile Number</th>
                      <th>Email id</th>
                      <th>Role</th>
                      <th>depo</th>
                      {loginUserTypeType === "ADMIN_MANAGER" ||
                        loginUserTypeType === "SUPER_ADMIN" ? (
                        <th>Action</th>
                      ) : null}
                      {archivedVal !== "Restore" && <th>view</th>}
                    </tr>
                  ) : (
                    <tr>
                      <th> Sr No. </th>
                      <th> First Name </th>
                      <th>User Name</th>
                      <th>user Type</th>
                      <th>Created at</th>
                      <th>Last login</th>
                      {loginUserTypeType === "SUPER_ADMIN" && <th>Action</th>}
                    </tr>
                  )}
                </thead>

                {modelType === "ADMIN_MANAGER" ? (
                  <tbody>
                    {/* usertable */}
                    {(archivedVal === "Archived" ? userData : archiveData )?.map((arr, i) => {
                      return (
                        <tr key={arr.id}>
                          <td>{archivedVal === "Restore"? (++i + 10 * (pageNumberArchive - 1)) : (++i + 10 * (pageNumber - 1))}</td>
                          <td>
                            {arr?.first_name} {arr?.last_name}&nbsp;
                            <span>
                              {arr?.company?.company_name
                                ? `(${arr?.company?.company_name})`
                                : "(Not Assign)"}
                            </span>
                          </td>
                          <td>{arr?.username}</td>
                          <td>
                            <span> +{arr?.country_code}&nbsp;{arr?.mobile}</span>
                          </td>
                          <td>
                            <span className="thm-clr">{arr?.email}</span>
                          </td>
                          <td>{arr?.user_type}</td>
                          <td><select className="form-control">
                           {arr?.depo_details?.map((arr)=>(<option  key={arr?.id}> {arr.depo_name}</option>)) }
                            </select>
                            </td>
                          {loginUserTypeType === "ADMIN_MANAGER" ||
                            loginUserTypeType === "SUPER_ADMIN" ||
                            loginUserTypeType === "CLEANING_MANAGER" ? (
                            <td>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() =>
                                  archiveUser(arr?.id, arr?.archived)
                                }
                              >
                                {archivedVal !== "Restore" ? (
                                  <i className="fa fa-cloud-upload"></i>
                                ) : (
                                  <i className="fa fa-cloud-download"></i>
                                )}
                              </button>
                              &nbsp;
                              {archivedVal !== "Restore" ? (
                                <>
                                  <Link
                                    to={`/user-edit/${modelType}/${arr.id}`}
                                    className="btn btn-outline-warning btn-sm"
                                  >
                                    <i className="fa fa-edit" />
                                  </Link>
                                </>
                              ) : (
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#pupup"
                                  onClick={() => setDeleteid(arr?.id)}
                                  className="btn btn-outline-danger btn-sm ms-1"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              )}
                            </td>
                          ) : null}
                          {archivedVal !== "Restore" && (
                            <td>
                              <Link
                                className="btn btn-outline-info btn-sm"
                                to={`/User-view/${arr.id}`}
                              >
                                <i className="fa fa-eye" />
                              </Link>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    {/* admin Table */}
                    {adminData?.map((arr, i) => {
                      return (
                        <tr key={arr?.id}>
                          <td>{++i + 10 * (pageNumberAdmin - 1)}</td>
                          <td>
                            {arr?.first_name}&nbsp;{arr?.last_name} &nbsp;
                            <span>
                              {arr?.company?.company_name
                                ? `(${arr?.company?.company_name})`
                                : "(Not Assign)"}
                            </span>
                          </td>
                          <td>{arr?.username}</td>
                          <td>{arr?.user_type}</td>
                          <td>{arr?.created_at?.split("T")[0]}</td>
                          <td>{arr?.last_login?.split("T")[0]}</td>
                          {loginUserTypeType === "SUPER_ADMIN" ? (
                            <td>
                              <Link
                                to="#"
                                className="fz-20 text-danger"
                                onClick={() =>
                                  archiveUser(arr?.id, arr?.archived)
                                }
                              >
                                <i className="fa fa-trash " />
                              </Link>
                              <Link
                                to={`/user-edit/${modelType}/${arr.id}`}
                                className="fz-20 text-warning mx-1"
                              >
                                <i className="fa fa-edit " />
                              </Link>
                            </td>
                          ) : null}
                          <td />
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} />
     {(modelType === "ADMIN_MANAGER" && archivedVal === "Archived")&& <Pagination pageNumber={pageNumber} pageLimit={pageLimit} setPageNumber={setPageNumber} />}
     {(modelType === "SUPER_ADMIN" && archivedVal === "Archived") && <Pagination pageNumber={pageNumberAdmin} pageLimit={pageLimitAdmin} setPageNumber={setPageNumberAdmin} />}
      {archivedVal === "Restore"&& <Pagination pageNumber={pageNumberArchive} pageLimit={pageLimitArchive} setPageNumber={setPageNumberArchive} />}
    </>
  );
}

{
  /* <UserListTable
userData={userData}
setModelType={setModelType}
deleteHandle={deleteHandle}
/> */
}
// modelType === "SUPER_ADMIN"
//   ? null
//   : modelType === "ADMIN_MANAGER"
//   ? "d-none"
//   : null
//  modelType === "SUPER_ADMIN" ? null : "d-none"

// onClick={() => setModelType("ADMIN_MANAGER")}
// {modelType === "ADMIN_MANAGER"
// ? "Add User"
// : "Add admin"}