import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./config/baseUrl";
import axios from "axios"
import { ToastMessgae } from "./Component/utils/toast";
export default function Protected({ Component }) {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  let [next,setNext]=useState(0)
  useEffect(() => {
    if (!token) {
      return navigate("/login");
    }
    if(token){
      // console.log("icall");
      (async () => {
        let response = await axios(`${baseUrl}/module/admin/token-check`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token") ,
          },
        });
        // console.log(response.data.data,"header ")
        // console.log(response,"header ")
        if((response.data.code === 401)){
          console.log(401)
          localStorage.clear()
          navigate("/login")
          ToastMessgae({message:"Please Login First"})
        }
        setNext(response.data.data || 0)
      })();
    }
  },[navigate]);
  return (
    <>
    {/* {console.log("Isworking" ,next)} */}
      {next && <Component />  }
      {/* {next ? <Component /> : navigate("/login") } */}
    </>
  );
}
