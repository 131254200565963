import React from "react";
import { Link } from "react-router-dom";

export default function Pagination({ pageNumber, setPageNumber ,pageLimit}) {
  return (
    <>
      <div >
        <nav aria-label="Page navigation example">
          <ul className="pagination ">
            {pageNumber > 1 &&<>
             <li
              className="page-item"
              onClick={() => setPageNumber((prev) => prev - 1)}
            >
              <Link className="page-link" to="#">
                Previous
              </Link>
            </li>
            <li
                className="page-item "
                onClick={() => setPageNumber((prev) => prev - 1)}
            >
                <Link className="page-link" to="#">
                {pageNumber - 1}
                </Link>
          </li>
           </>}

            <li className={`page-item active `}>
              <Link className="page-link" to="#">
                {pageNumber}
              </Link>
            </li>

           {pageNumber < pageLimit && <> <li
              className="page-item "
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <Link className="page-link" to="#">
                {pageNumber + 1}
              </Link>
            </li>
            {/* <li
              className="page-item"
              onClick={() => setPageNumber((prev) => prev + 2)}
            >
              <Link className="page-link" to="#">
                {pageNumber + 2}
              </Link>
            </li> */}
            <li 
              className="page-item"
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              <Link className="page-link" to="#">
                Next
              </Link>
            </li>
            </>}
          </ul>
        </nav>
      </div>
    </>
  );
}
