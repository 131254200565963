import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
// import jobImg from "../../assets/images/jobimg.png";
// import avtar from "../../assets/images/avtar.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
// import { Link, Navigate } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import Bus_img from "../../assets/images/bus_img.png";
import Swal from "sweetalert2";
import { baseUrl } from "../../config/baseUrl";
import { ToastMessgae } from "../utils/toast";
export default function JobSchedule() {
  let loginUser = JSON.parse(localStorage.getItem("user"));
  let [scheduleData, setScheduleData] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLimit, setPageLimit] = useState(1);
  let [showdata, setShowdata] = useState("Active"); //complete
  let [extra, setExtra] = useState({});
  let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);

  let [searchData, setSearchData] = useState({
    company: "",
    bus__depo: "",
    cleaning_type: "",
    Cleaning_type: "",
    assign_date: "",
    bus_bus_number: "",
    report_done: "",
  });

  let ChangeHandler = (e) => {
    setSearchData((curr) => {
      return { ...curr, [e.target.name]: e.target.value };
    });
  };

  //

  const apiCall = async () => {
    try {
      setShowdata("Active");
      let response = await axios(
        `${baseUrl}/module/admin/adminscheduler-viewset?page=${pageNumber}&company=${searchData.company}&bus__depo=${searchData.bus__depo}&cleaning_type=${searchData?.cleaning_type}&assign_date=${searchData.assign_date}&bus__bus_number=${searchData?.bus_bus_number}&report_done=${searchData.report_done}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log(response?.data);
      // console.log(response?.data?.extra.total)
      setScheduleData(response?.data?.data || []);
      setPageLimit(response?.data?.extra.total);
      setExtra(response?.data?.extra);
    } catch (error) {
      alert(error.message + ", Some error form sever");
      // Navigate("/");
    }
  };

  const depo = async () => {
    let response = await axios(`${baseUrl}/module/admin/depo-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    // console.log(response.data);
    // setPageLimit(response?.data?.extra?.total);
    setDepoData(response?.data?.data || []);
  };
  // depo();
  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    // console.log(response.data);
    setCompanyNameData(response.data?.data || []);
    //  setPageLimit(response?.data?.extra?.total);
  };

  useEffect(() => {
    apiCall();
  }, [pageNumber]);

  useEffect(() => {
    depo();
    companyName();
  }, []);

  let handleSearch = async (e) => {
    e.preventDefault();
    console.log(searchData);
    // return
    setPageNumber(1)
    try {
      // let response = await axios(`${baseUrl}/module/admin/search-scheduler`, {
      let response = await axios(
        `${baseUrl}/module/admin/adminscheduler-viewset?company=${searchData.company}&bus__depo=${searchData.bus__depo}&cleaning_type=${searchData?.cleaning_type}&assign_date=${searchData.assign_date}&bus__bus_number=${searchData?.bus_bus_number}&report_done=${searchData.report_done}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          // data: searchData,
        }
      );
      // console.log(response.data);
      if (response.data.code === 200) {
        setScheduleData(response.data.data || []);
        // setScheduleData(response?.data?.data)
        setPageLimit(response?.data?.extra.total);
        setExtra(response?.data?.extra);
        // return
      } else if (response.data.code === 404) {
        ToastMessgae(response.data);
      } else if (response.data.code === 400) {
        ToastMessgae(response.data);
      }
    } catch (error) {
      alert("some error from Backend");
    }
  };

  let deleteHandle = (id) => {
    let apiCall = async () => {
      let response = await axios(
        `${baseUrl}/module/admin/adminscheduler-viewset/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.code === 403) {
        return Swal.fire({
          title: `${response.data.message}`,
          icon: "error",
        });
      }
      setScheduleData((prvVal) => {
        prvVal = prvVal.filter((arr) => arr.id !== response?.data?.data?.id);
        return [...prvVal];
      });
    };
    Swal.fire({
      title: "Are you sure..?",
      text: `You won't be able to revert this..!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        apiCall();
        Swal.fire({
          title: "deleted...!",
          text: `Scheduler has been delete..`,
          icon: "success",
        });
      }
    });
  };

  return (
    <>
      <Header />
      <Sidebar activeItem={"Rostering"} />
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-suitcase me-2" />
                Job Schedule lobby
              </h4>
              <div >
                <Link to="/depo-list-for-change-scheduling" className="blu-btn me-2">
                  <i className="fa fa-calendar me-2" />
                  change schedule
                </Link>
                <Link to="/job-schedule-form" className="blu-btn">
                  <i className="fa fa-plus me-2" />
                  Add New Job
                </Link>
              </div>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Job Schedule List ({extra?.count})
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cstm-tab">
              <div className="tab-content " id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="tab-01"
                  role="tabpanel"
                >
                  <Link
                      class="btn btn-primary mb-2 ms-1"
                      to={`https://admin.cleaningcrm.fluttertrends.com/module/admin/download-scheduler-excel/${loginUser?.id}?company=${searchData.company}&bus__depo=${searchData.bus__depo}&cleaning_type=${searchData?.cleaning_type}&assign_date=${searchData.assign_date}&bus__bus_number=${searchData?.bus_bus_number}&report_done=${searchData.report_done}`}
                    >
                      <i class="fa fa-download  me-2"></i> CSV
                    </Link>
                  <div className="cards bus-list">
                    <div className="bus-filter">
                      <div className="row justify-content-end">
                        <div className="col-lg-12 text-end">
                          <form action="" className="text-end">
                            <div className="row justify-content-end">
                              {loginUser?.user_type === "SUPER_ADMIN" && (
                                <div className="col ">
                                  <select
                                    name="company"
                                    id=""
                                    onChange={ChangeHandler}
                                    className="form-select"
                                    value={searchData.company}
                                  >
                                    <option value="">
                                      Select Company
                                    </option>
                                    {companyNameData?.map((arr) => (
                                      <option value={arr?.id} key={arr?.id}>
                                        {arr?.company_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              <div className="col">
                                <select
                                  name="bus__depo"
                                  onChange={ChangeHandler}
                                  className="form-select"
                                  value={searchData.bus__depo}
                                >
                                  <option value="">Select Depo</option>
                                  {depoData?.map((arr) => (
                                    <option key={arr?.id} value={arr?.id}>
                                      {arr?.depo_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col">
                                <select
                                  name="cleaning_type"
                                  onChange={ChangeHandler}
                                  className="form-select"
                                  value={searchData.cleaning_type}
                                >
                                  <option value="">Select Cleaning Type</option>
                                  <option value="REGULAR_CLEANING">
                                    Regular Cleaning
                                  </option>
                                  ;
                                  <option value="STANDARD_DEEP_CLEANING">
                                    Standard Deep Cleaning
                                  </option>
                                  <option value="ANNUAL_DEEP_CLEANING">
                                    Annual Deep Cleaning
                                  </option>
                                </select>
                              </div>
                              <div className="col">
                                <input
                                  onChange={ChangeHandler}
                                  name="assign_date"
                                  type="date"
                                  className="form-control"
                                  placeholder="Assign date Type..."
                                  value={searchData?.assign_date}
                                />
                              </div>
                              <div className="col ">
                                <select
                                  name="report_done"
                                  onChange={ChangeHandler}
                                  className="form-select"
                                  value={searchData.report_done}
                                >
                                  <option value="">Select status</option>
                                  <option value={true}>Complete</option>
                                  <option value={false}>Pending</option>
                                </select>
                              </div>
                              <div className="col">
                                <input
                                  onChange={ChangeHandler}
                                  name="bus_bus_number"
                                  type="text"
                                  className="form-control"
                                  placeholder="Bus Name ..."
                                  value={searchData.bus_bus_number}
                                />
                              </div>
                              <div className="col">
                                <button
                                  className="thm-btn w-100"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      {scheduleData?.map((arr) => (
                        <div
                          key={arr?.id}
                          className="col-lg-3 col-md-6 col-sm-6"
                        >
                          <div className="job-shch-card">
                            {(loginUser.user_type === "ADMIN_MANAGER" || loginUser?.user_type === "CLEANING_MANAGER") ? <Link to={`/change-scheduling/${arr?.bus?.depo}`}>
                              <div className="job-shch-card-img">
                                <img src={Bus_img} alt="" />
                              </div>
                            </Link> : <div className="job-shch-card-img">
                              <img src={Bus_img} alt="" />
                            </div>}
                            <div className="job-shch-card-cntnt">
                              <p className="date">
                                <i className="fa fa-calendar-alt me-1" />
                                {arr?.assign_date}
                              </p>
                              <h5>{arr?.bus?.depo_name}</h5>
                              <p className="assigned">
                                Bus Number:<span>{arr?.bus?.bus_number}</span>
                              </p>
                              <p className="mb-0">
                                Depo Address :
                                <span className="assigned-prfl">
                                  {arr?.bus?.depo_address}
                                </span>
                              </p>
                            </div>
                            <div className="job-shch-card-bttm">
                              <Link to="#" className="thm-btn">
                                {arr?.cleaning_type}
                              </Link>
                              <div className="dropdown cstm-menu " >
                                <button
                                  type="button"
                                  id="jobmenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className={arr?.cleaning_type === 'REGULAR_CLEANING' ? 'bg-success text-white' : arr?.cleaning_type === 'ANNUAL_DEEP_CLEANING' ? 'bg-danger' : arr?.cleaning_type === 'STANDARD_DEEP_CLEANING' && `bg-warning`}
                                >
                                  <i className="fa fa-list" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="jobmenu"
                                >
                                  {/* <li>
                                    <Link className="dropdown-item" to="#">
                                      Edit Name
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to={`/job-schedule-edit/${arr?.id}`}
                                    >
                                      Edit
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link className="dropdown-item" to="#">
                                      Archive
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link
                                      onClick={() => deleteHandle(arr.id)}
                                      className="dropdown-item"
                                      to="#"
                                    >
                                      Delete
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              {arr.report_done === true ? (
                                <span className="status complete">
                                  Complete
                                </span>
                              ) : (
                                <span className="status pending">Pending</span>
                              )}
                              {/* <span className="status complete">Complete</span> */}
                              {/* <span className="status pending">Pending</span> */}
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="job-shch-card">
                          <div className="job-shch-card-img">
                            <img src={jobImg} alt="" />
                          </div>
                          <div className="job-shch-card-cntnt">
                            <p className="date">
                              <i className="fa fa-calendar-alt me-1" />
                              23/03/2024
                            </p>
                            <h5>Bus Cleaning</h5>
                            <p className="assigned">
                              Assigned :<span>Ajay Sharma</span>
                            </p>
                            <p className="mb-0">
                              Admin :{" "}
                              <span className="assigned-prfl">
                                <img src={avtar} alt="" />
                                Sunil Sharma
                                <em className="crown fa fa-crown" />
                              </span>
                            </p>
                          </div>
                          <div className="job-shch-card-bttm">
                           <Link to="#" className="thm-btn">
                              Access schedule
                           </Link>
                            <div className="dropdown cstm-menu">
                              <button
                                type="button"
                                id="jobmenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-list" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="jobmenu"
                              >
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Name{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Edit Assignments{" "}
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Archive
                                 </Link>
                                </li>
                                <li>
                                 <Link className="dropdown-item" to="#">
                                    Delete
                                 </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <Pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      pageLimit={pageLimit}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="tab-pane fade" id="tab-02" role="tabpanel">
                <div className="cards bus-list">
                  <div className="bus-filter">
                    <div className="row justify-content-end">
                      <div className="col-lg-8 text-end">
                        <form action="" className="text-end">
                          <div className="row justify-content-end">
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                            </div>
                            <div className="col-lg-2">
                              <button className="blu-btn">
                                <i className="fa fa-plus me-2" />
                                Add New{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="job-shch-card">
                        <div className="job-shch-card-img">
                          <img src={jobImg} alt="" />
                        </div>
                        <div className="job-shch-card-cntnt">
                          <p className="date">
                            <i className="fa fa-calendar-alt me-1" />
                            23/03/2024
                          </p>
                          <h5>Bus Cleaning</h5>
                          <p className="assigned">
                            Assigned :<span>Ajay Sharma</span>
                          </p>
                          <p className="mb-0">
                            Admin :{" "}
                            <span className="assigned-prfl">
                              <img src={avtar} alt="" />
                              Sunil Sharma
                              <em className="crown fa fa-crown" />
                            </span>
                          </p>
                        </div>
                        <div className="job-shch-card-bttm">
                         <Link to="#" className="thm-btn">
                            Access schedule
                         </Link>
                          <div className="dropdown cstm-menu">
                            <button
                              type="button"
                              id="jobmenu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-list" />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="jobmenu"
                            >
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Edit Name{" "}
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Edit Assignments{" "}
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Archive
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Delete
                               </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="job-shch-card">
                        <div className="job-shch-card-img">
                          <img src={jobImg} alt="" />
                        </div>
                        <div className="job-shch-card-cntnt">
                          <p className="date">
                            <i className="fa fa-calendar-alt me-1" />
                            23/03/2024
                          </p>
                          <h5>Bus Cleaning</h5>
                          <p className="assigned">
                            Assigned :<span>Ajay Sharma</span>
                          </p>
                          <p className="mb-0">
                            Admin :
                            <span className="assigned-prfl">
                              <img src={avtar} alt="" />
                              Sunil Sharma
                              <em className="crown fa fa-crown" />
                            </span>
                          </p>
                        </div>
                        <div className="job-shch-card-bttm">
                         <Link to="#" className="thm-btn">
                            Access schedule
                         </Link>
                          <div className="dropdown cstm-menu">
                            <button
                              type="button"
                              id="jobmenu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-list" />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="jobmenu"
                            >
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Edit Name{" "}
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Edit Assignments{" "}
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Archive
                               </Link>
                              </li>
                              <li>
                               <Link className="dropdown-item" to="#">
                                  Delete
                               </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
